export default {
  name: "TheHeaderDropdownAccnt",
  data() {
    return {};
  },
  methods: {
    logout() {
      sessionStorage.clear();
      window.location.reload();
    },
  },
};
