import TheSidebar from "../the-sidebar/TheSidebar";
import TheHeader from "../the-header/TheHeader";

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
  },
};
