import SideNavTree from "../../components/sidenav-tree";

export default {
  name: "TheSidebar",
  components: {
    SideNavTree,
  },
  data() {
    return {
      dto: {
        sidebars: [],
        naturalSidebarData: [],
      },
      root: [],
      sidebar_main_image: "",
      validationDomain: "",
      sidebar_logo_image: process.env.VUE_APP_SIDEBAR_MAIN_ICON,
      valPojok: process.env.VUE_APP_IS_POJOK,
    };
  },
  methods: {
    async getAsset() {
      let loading = false;
      let session = null;
      while (!session && !loading) {
        loading = true;
        console.log("fetch asset");
        session = sessionStorage.getItem("@vue-session/asset-bundle");
        await this.simpleWait(1000);
        loading = false;
      }
      this.sidebar_main_image = JSON.parse(window.atob(session)).sidebarImg;
    },
    async helperGetDataSidebars() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "user-management",
          reqUrl: "user-role/get-menu-access",
        });
        if (resp.data.code === "SUCCESS") {
          this.root = resp.data.data;
        } else {
          this.dto.sidebars = [
            { name: "Anda Tidak Memiliki Akses", isUnRoled: true },
          ];
        }
      } catch (error) {
        console.log(error.response);
      }
    },

    handleBackToMain() {
      if (this.property.headerPosition === "Dashboard") {
        this.$router.push("/main");
      }
    },
  },
  mounted() {
    this.validationDomain = window.location.hostname;
    this.helperGetDataSidebars();
    this.getAsset();
  },

  computed: {
    show() {
      return this.$store.state.sidebar.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebar.sidebarMinimize;
    },
  },
};
