import TheHeaderDropdownAccnt from "../the-header-dropdown/TheHeaderDropdownAccnt";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Moment from "moment";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      validationDomain: "",
      processStatus: false,
      timerCount: 0,
      property: {
        modal: {
          showModalPassword: false,
        },
        animation: {
          isLoading: false,
        },
      },
      officeName: "",
      userName: "",
      passwordOld: "",
      passwordNew: "",
      passwordConfirm: "",
      date: "",
    };
  },
  watch: {
    timerCount: {
      async handler(val) {
        if (val > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
          this.processStatus = await this.fetchOfficeStatus();
          this.date = await this.fetchSystemDate();
          this.timerCount = 5;
        }
      },
      immediate: true,
    },
  },
  methods: {
    Moment: Moment,
    fetchOfficeStatus() {
      return new Promise(async (resolve, reject) => {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            reqUrl: "operational-process/status-active",
            endPoint: "process",
          });
          if (resp.data.code === "SUCCESS") {
            resolve(resp.data.data.processStatus);
            return;
          }
          resolve("");
        } catch (error) {
          resolve("");
        }
      });
    },
    fetchSystemDate() {
      return new Promise(async (resolve, reject) => {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "process",
            reqUrl: "system",
          });
          if (resp.data.code === "SUCCESS") {
            resolve(resp.data.data.system_date);

            sessionStorage.setItem(
              "@vue-session/application",
              this.encryptBASE64(JSON.stringify(resp.data.data))
            );
            return;
          }
          resolve("");
        } catch (error) {
          resolve("");
        }
      });
    },
    async logout() {
      try {
        await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "user-management",
          reqUrl: "user-signin-log",
        });
      } catch (error) {
      } finally {
        await this.simpleWait(200);
        sessionStorage.clear();
        window.location.reload();
      }
    },
    async handleSubmitChangePassword() {
      const payload = {
        oldPassword: this.passwordOld,
        newPassword: this.passwordNew,
        newConfirmPassword: this.passwordConfirm,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.property.animation.isLoading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "POST_DATA_HEADERS",
            endPoint: "user-management",
            reqUrl: "v2/user/password",
            payload,
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.property.modal.showModalPassword = false;
              this.$buefy.dialog.alert({
                title: "Success !",
                message: "Password berhasil diubah, silahkan login kembali.",
                confirmText: "Kembali ke Halaman Login",
                type: "is-success",
                onConfirm: () => this.logout(),
              });
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: `${resp.data.message}`,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          }, 1500);
        } catch (error) {
          setTimeout(() => {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, 1500);
        } finally {
          setTimeout(() => {
            this.property.animation.isLoading = false;
          }, 1000);
        }
      }
    },
    handleCloseModalPassword() {
      this.property.modal.showModalPassword = false;
      this.passwordOld = "";
      this.passwordNew = "";
      this.passwordConfirm = "";
    },
    // async getDate() {
    //   try {
    //     const resp = await this.$store.dispatch({
    //       type: "GET_LIST_DATA",
    //       endPoint: "process",
    //       reqUrl: "system",
    //     });
    //     this.date = resp.data.data.system_date;
    //   } catch (error) {}
    // },
  },
  mounted() {
    this.validationDomain = window.location.hostname;
    const getUserPayload = JSON.parse(
      this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
    );
    this.userName = getUserPayload.userName;
    this.officeName = getUserPayload.officeName;
    Moment.locale("ID");
  },
};
