export default {
  name: "SideNavTree",
  props: {
    nodes: Array,
    depth: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      expanded: [],
    };
  },
  methods: {
    isExpanded(node) {
      return this.expanded.indexOf(node) !== -1;
    },
    nodeClicked(node) {
      if (node.url) {
        if (
          this.$route.meta.routerLink === `${node.url}` &&
          this.$route.meta.isNeedReMount === true
        ) {
          this.$store.dispatch("SET_IS_SHOW_ROUTER", false);
          setTimeout(() => {
            this.$store.dispatch("SET_IS_SHOW_ROUTER", true);
          }, 500);
        } else {
          this.$router.push(`/${node.url}`);
        }
        return;
      }

      // if (!this.isExpanded(node)) {
      //   this.expanded.push(node);
      // } else {
      //   this.expanded.splice(this.expanded.indexOf(node));
      // }

      const isAlreadyExpanded = this.isExpanded(node);

      this.expanded = [];

      if (!isAlreadyExpanded) {
        this.expanded.push(node);
      }

    },
  },
  components: {},
  computed: {},
};
