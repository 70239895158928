var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full side-nav "},_vm._l((_vm.nodes),function(node){return _c('div',{key:node.name,staticClass:" menu-parent ",class:node.accessModules.length
          ? _vm.isExpanded(node) ? 'expand-nav   mb-0' : 'not-expand my-4' : 'span mt-4 mb-0'},[_c('button',{class:node.accessModules.length
          ? _vm.isExpanded(node)
            ? 'justify-center items-center capitalize global-tree main-tree-nav border-none font-bold text-left flex   outline-none  '
            : 'justify-center items-center global-tree border-none font-normal text-left flex   outline-none '
          : 'justify-center items-center global-tree child-tree-nav font-normal text-left flex  ',on:{"click":function($event){return _vm.nodeClicked(node)}}},[_c('div',{class:node.accessModules.length
            ? _vm.isExpanded(node)
              ? 'text-white color-expanded-tree-nav flex justify-between flex-row items-center'
              : 'color-not-expanded-tree-nav flex justify-between flex-row items-center'
            : 'color-child-tree-nav flex justify-between flex-row items-center'},[_vm._v(" "+_vm._s(node.name)+" "),_c('b-icon',{attrs:{"icon":node.accessModules.length
              ? _vm.isExpanded(node)
                ? 'chevron-down'
                : 'chevron-right'
              : 'jump',"size":"is-small"}})],1)]),(_vm.isExpanded(node) && node.accessModules.length)?_c('SideNavTree',{staticClass:" menu-sub-parent",attrs:{"nodes":node.accessModules,"depth":_vm.depth + 1},on:{"onClick":function (node) { return _vm.$emit('onClick', node); }}}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }